import TcaImg from 'media/institute/technical-equipment/tca.png';
import DnaImg from 'media/institute/technical-equipment/dna.png';

export default [
    {
        titleKZ: 'Электронды тахеометр TCA 2003',
        titleRU: 'Электронный тахеометр TCA 2003',
        titleEN: 'Electronic Theodolite TCA 2003',
        textKZ: 'Мақсаты және қолдану саласы: Электронды тахеометрлер ТС 2003 және ТСА 2003 горизонтальды және вертикальды бұрыштарды, қашықтықтарды және олардың функцияларының мәндерін өлшеуге арналған. Олар: тірек және түсіру геодезиялық желісін құруда; топографиялық және арнайы түсірулерді жүргізуде; кадастр үшін аумақтардың шекараларын анықтауда; құрылыс-монтаждау жұмыстарын геодезиялық қамтамасыз етуде; ғимараттар мен құрылыстардың деформацияларын бақылауда; жер жұмыстарының көлемін анықтауда, сондай-ақ координаттарды жоғары дәлдікпен (соның ішінде жедел) анықтауды талап ететін басқа жұмыстарды орындауда қолданылады.',
        textRU: 'Назначение и область применения: Тахеометры электронные ТС 2003 и ТСА 2003 предназначены для измерения горизонтальных и вертикальных углов, расстояний и определения значений их функций. Используются при: создании опорной и съемочной геодезической сети; производстве топографических и специальных съемок; определении границ территорий для ведения кадастров; геодезическом обеспечении строительно-монтажных работ; наблюдении за деформациями зданий и сооружений; определении объемов земляных работ, а также при выполнении других работ, где требуются высокоточные определения (в том числе оперативные) координат.',
        textEN: 'Purpose and application: The TCA 2003 and TCA 2003 electronic theodolites are designed for measuring horizontal and vertical angles, distances, and determining the values of their functions. They are used for: creating a reference and survey geodetic network; producing topographic and special surveys; determining the boundaries of areas for cadastral purposes; geodetic support for construction and installation works; monitoring the deformations of buildings and structures; determining the volumes of earthworks, as well as performing other works where high-precision (including operational) determination of coordinates is required.',
        img: TcaImg,
    },

    {
        titleKZ: 'Цифрлы нивелир DNA 03',
        titleRU: 'Цифровой нивелир DNA 03',
        titleEN: 'Digital Level DNA 03',
        textKZ: 'Дәл және сенімді цифрлы нивелир Leica DNA03 ең күрделі жұмыстарды орындау үшін арналған, бұл құрал өлшеу дәлдігін арттыруды талап етеді. Бұл құралдың көмегімен 1-ші және 2-ші класты жоғары дәлдікті нивелирлеуді жүргізуге болады, бұл дәстүрлі оптикалық нивелирлерге қарағанда өнімділікті 50 пайызға арттырады, осылайша нивелирлеу жұмыстарын орындауға еңбек және уақыт шығындарын азайтады.',
        textRU: 'Точный и надежный цифровой нивелир Leica DNA03 предназначен для самых сложных работ, требующих повышенной точности измерений. При помощи этого инструмента можно производить высокоточное нивелирование 1-го и 2-го класса, при этом по сравнению с традиционными оптическими нивелирами наблюдается 50-процентное повышение производительности, а следовательно, уменьшаются трудовые и временные затраты на выполнение большинства нивелировочных работ.',
        textEN: 'The precise and reliable Leica DNA03 digital level is designed for the most complex tasks requiring increased measurement accuracy. With this instrument, high-precision leveling of the 1st and 2nd class can be performed, resulting in a 50 percent increase in productivity compared to traditional optical levels, thereby reducing labor and time costs for most leveling tasks.',
        img: DnaImg,
    },
];
